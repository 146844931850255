import { SITE_ADMIN, USER_ROLES, USER_PERMISSIONS } from 'utils/constants';

export const isModerator = (userPermissions) =>
  userPermissions &&
  userPermissions.some((permission) =>
    permission.split(', ').includes(USER_PERMISSIONS.VIEW_COMMUNITY)
  );

export const isSiteAdmin = (userRoles) =>
  userRoles && userRoles.some((role) => role.split(', ').includes(SITE_ADMIN));

export const isAdmin = (user) => {
  return canAccess(
    [
      USER_ROLES.SITE_OWNER_SITE_ADMIN,
      USER_ROLES.SITE_ADMIN,
      USER_ROLES.PARTNER,
    ],
    user
  );
};

export const canAccess = (roles, currentUser) => {
  const userRoles = currentUser?.roles?.map((userRole) =>
    userRole.toLowerCase()
  );

  // If the user does not have roles and we need to return false.
  // There is a case where the author does not have roles.
  if (!userRoles) {
    return false;
  }

  const checkRole = (role) => {
    return userRoles.includes(role.toLowerCase());
  };

  if (!roles.some(checkRole)) return false;

  return true;
};

export const canDeletePost = (currentUser) => {
  return (
    isSiteAdmin(currentUser.roles) ||
    hasPermission(currentUser.permissions, USER_PERMISSIONS.DELETE_POST)
  );
};

export const canDeleteComment = (currentUser, author) => {
  return (
    isSiteAdmin(currentUser.roles) ||
    (currentUser.id && currentUser.id === author?.id) ||
    hasPermission(currentUser.permissions, USER_PERMISSIONS.DELETE_POST)
  );
};

export const canEditPost = (currentUser, author) => {
  return (
    isSiteAdmin(currentUser.roles) ||
    (currentUser.id && currentUser.id === author?.id)
  );
};

export const canPinPost = (currentUser) => {
  return (
    isSiteAdmin(currentUser.roles) ||
    hasPermission(currentUser.permissions, USER_PERMISSIONS.PIN_POST)
  );
};

export const canCreateEvent = (currentUser) => {
  return (
    isAdmin(currentUser) ||
    hasPermission(currentUser.permissions, USER_PERMISSIONS.CREATE_LIVE_EVENT)
  );
};

export const canEditEvent = (currentUser) => {
  return (
    isAdmin(currentUser) ||
    hasPermission(currentUser.permissions, USER_PERMISSIONS.EDIT_LIVE_EVENT)
  );
};

export const canViewMemberList = (currentUser) => {
  return (
    isAdmin(currentUser) ||
    hasPermission(currentUser.permissions, USER_PERMISSIONS.VIEW_COMMUNITY)
  );
};

export const hasModeratorBadge = (user) => {
  return isSiteAdmin(user.roles) || isModerator(user.permissions);
};

export const hasPermission = (userPermissions, permission) =>
  userPermissions &&
  userPermissions.some((role) => role.split(', ').includes(permission));
