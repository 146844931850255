import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import PubSub from 'pubsub-js';

import { PRODUCT_QUERY } from 'schema/Product/queries';
import { genericToastAlert } from 'utils/toastHelpers';
import { RELATABLE_TYPES } from 'constants/relatableTypes';
import { goToEditSpace, pathnames, routes } from 'utils/routerHelpers';
import { eventNames } from 'constants/eventNames';
import classNames from 'classnames/bind';
import {
  CourseFieldsFragment,
  CommunityProductRelationsQuery,
} from '__generated__/graphql/legacy/graphql';
import Opacity from 'components/Opacity/Opacity';
import { useCommunityContext } from 'components/CommunityContext';
import { isSiteAdmin } from 'utils/userHelpers';
import { SpaceDetailsCard } from 'components/SpaceDetails';
import { useTranslation } from 'react-i18next';
import { Button } from '@thinkific/toga-react';
import SpaceDetailsSkeleton from 'components/SpaceDetails/components/SpaceDetailsSkeleton/SpaceDetailsSkeleton';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import CourseCard from './CourseCard';
import CourseCardPlaceholder from './CourseCardPlaceholder/CourseCardPlaceholder';

import styles from './CourseDetails.module.scss';

const cx = classNames.bind(styles);

const CourseDetails: React.FC = () => {
  const [courseInfo, setCourseInfo] = useState<CourseFieldsFragment>();
  const { currentUser, community, trackEvent } = useCommunityContext();
  const router = useRouter();
  const { t } = useTranslation();
  const { spaceId, communityId } = router.query;
  const isEditSpacePage = router.pathname === pathnames.spaces.edit;
  const isNewSpacePage = router.pathname === pathnames.spaces.new;
  const isSpacePage = router.pathname === pathnames.spaces.space;
  const isAdmin = isSiteAdmin(currentUser.roles);

  const { data, loading } = useQuery<CommunityProductRelationsQuery>(
    PRODUCT_QUERY,
    {
      variables: {
        communityId,
        relatableType: RELATABLE_TYPES.COMMUNITY_SPACE,
        relatableId: spaceId,
        isModerator: isAdmin,
      },
      fetchPolicy: 'cache-and-network',
      skip: isNewSpacePage,
      onError: () => {
        genericToastAlert();
      },
    }
  );

  const productRelation =
    data?.community?.communityProductRelations?.edges?.[0];
  const courseProduct = productRelation?.node?.product?.productable;
  const slug = productRelation?.node?.product?.slug;

  useEffect(() => {
    if (courseProduct?.__typename === 'Course') {
      setCourseInfo(courseProduct);
    } else {
      setCourseInfo(undefined);
    }
  }, [courseProduct, router.asPath]);

  useEffect(() => {
    const tokenShow = PubSub.subscribe(
      eventNames.SHOW_COURSE_CARD_PREVIEW,
      (msg: string, info: CourseFieldsFragment) => {
        setCourseInfo(info);
      }
    );

    const tokenHide = PubSub.subscribe(
      eventNames.HIDE_COURSE_CARD_PREVIEW,
      () => {
        setCourseInfo(undefined);
      }
    );

    return () => {
      PubSub.unsubscribe(tokenShow);
      PubSub.unsubscribe(tokenHide);
    };
  }, []);

  const previewCourseUrl = `${routes.coursesPreview}/${slug}?preview_type=student`;

  // if the percentage completed is null, we know that the current_user must be an admin + has not enrolled in the course.
  const goToCourseUrl =
    courseInfo?.percentageCompleted === null
      ? previewCourseUrl
      : courseInfo?.resumeCourseUrl;

  let content;
  if (courseInfo) {
    const productId = productRelation?.node?.product?.id || '';

    content = (
      <Opacity value={isEditSpacePage || isNewSpacePage ? 0.7 : 1}>
        <CourseCard
          className={cx('course-card__container', {
            'is-not-clickable': isEditSpacePage || isNewSpacePage,
          })}
          courseURL={goToCourseUrl || ''}
          dataQA="course-card"
          description={courseInfo.description || ''}
          imageUrl={courseInfo.cardImageUrl || ''}
          name={courseInfo.name || ''}
          percentageCompleted={courseInfo.percentageCompleted}
          productId={productId}
        />
      </Opacity>
    );
  } else if (isEditSpacePage || isNewSpacePage) {
    content = <CourseCardPlaceholder />;
  } else if (isSpacePage && isAdmin) {
    if (loading || courseInfo !== courseProduct) {
      // If courseInfo !== courseProduct, then we need to wait for state to update as well
      return <SpaceDetailsSkeleton hideImageSkeleton />;
    }

    content = (
      <SpaceDetailsCard
        description={t(
          'components-courseCard-link_course_description',
          'Select a course that students in your community have access to, creating a more holistic, dynamic learning experience.'
        )}
        footerAccessory={
          <Button
            appearance="secondary"
            data-qa="link-a-course-cta"
            onClick={() => {
              trackEvent(TrackingEvent.CLICK_ADD_COURSE_TO_SPACE, {
                [TrackingProperty.COMPONENT]: 'CourseDetails',
              });
              goToEditSpace(router, spaceId as string, community.id, {
                openCourseModal: true,
              });
            }}
          >
            {t('LINK A COURSE')}
          </Button>
        }
        icon="link"
        inheritPrimaryColor={false}
        name={t('Link a Course')}
        showImage={false}
      />
    );
  }

  return <div data-qa-loading={loading}>{content}</div>;
};

export default CourseDetails;
