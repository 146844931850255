import { gql } from '@apollo/client';

export const COMMUNITY_EDGES_NODE_USER_FRAGMENT = gql`
  fragment CommunityEdgesNodeUserFields on User {
    id
    fullName
    profile {
      id
      avatarUrl
    }
    roles
  }
`;

export const COMMUNITY_EDGES_NODE_FRAGMENT = gql`
  fragment CommunityEdgesNodeFields on CommunityUser {
    id
    user {
      permissions(resourceId: $communityId, resourceType: Community)
      ...CommunityEdgesNodeUserFields
    }
  }
  ${COMMUNITY_EDGES_NODE_USER_FRAGMENT}
`;

export const COMMUNITY_EDGES_FRAGMENT = gql`
  fragment CommunityEdgesFields on CommunityUserEdge {
    node {
      ...CommunityEdgesNodeFields
    }
  }
  ${COMMUNITY_EDGES_NODE_FRAGMENT}
`;

export const USER_ENROLLMENT_FRAGMENT = gql`
  fragment UserEnrollmentFields on EnrollmentConnection {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        completedAt
        course {
          id
          name
          cardImageUrl
          resumeCourseUrl
          landingPageUrl
        }
      }
    }
  }
`;

export const USERS_QUERY = gql`
  query Users(
    $communityId: ID!
    $searchTerm: String = ""
    $numberOfUsers: Int = 5
    $after: String
  ) {
    community(id: $communityId) {
      id
      communityUsers(
        first: $numberOfUsers
        after: $after
        filter: { active: true }
      ) {
        totalCount
        pageInfo {
          endCursor
          startCursor
        }
        edges {
          ...CommunityEdgesFields
        }
      }
      mentionableUsers(searchTerm: $searchTerm, first: $numberOfUsers) {
        edges {
          node {
            id
            user {
              id
              fullName
              profile {
                id
                avatarUrl
              }
            }
          }
        }
      }
    }
  }
  ${COMMUNITY_EDGES_FRAGMENT}
`;

export const USER_QUERY = gql`
  query User(
    $communityId: ID!
    $userId: ID!
    $numOfCourses: Int
    $endCursor: String
  ) {
    community(id: $communityId) {
      id
      communityUser(userId: $userId) {
        id
        canModerate
        user {
          id
          fullName
          firstName
          roles
          profile {
            id
            avatarUrl
            company
            headline
            timezone
          }
          enrollments(
            filter: {
              status: COMPLETED
              productStatus: [published]
              productVisible: true
            }
            orderBy: { field: COMPLETED_AT, direction: DESC }
            first: $numOfCourses
            after: $endCursor
          ) {
            ...UserEnrollmentFields
          }
        }
      }
    }
  }
  ${USER_ENROLLMENT_FRAGMENT}
`;
