import React, { ReactNode } from 'react';
import { useRouter } from 'next/router';

import { useCommunityContext } from 'components/CommunityContext';
import PageSkeleton from 'components/PageSkeleton';
import { pathnames, goToHome } from 'utils/routerHelpers';
import { isAdmin } from 'utils/userHelpers';

interface RedirectUserProps {
  children: ReactNode;
}

const RedirectUser: React.FC<RedirectUserProps> = ({ children }) => {
  const {
    community: { id },
    currentUser,
  } = useCommunityContext();
  const router = useRouter();

  const isAdminPermitted = isAdmin(currentUser);

  const studentPermittedPaths = [
    pathnames.home,
    pathnames.following,
    pathnames.spaces.space,
    pathnames.post,
    pathnames.events.events,
    pathnames.events.event,
    pathnames.search,
  ];

  const isStudentPermitted = studentPermittedPaths.includes(router.pathname);

  const moderatorPermittedPaths = [
    ...studentPermittedPaths,
    pathnames.members,
    pathnames.events.new,
    pathnames.events.edit,
  ];

  const isModeratorPermitted =
    currentUser.isModerator &&
    moderatorPermittedPaths.includes(router.pathname);

  // Send user to Home if they are not members
  if (!isAdminPermitted && !isStudentPermitted && !isModeratorPermitted) {
    goToHome(router, id);
    return <PageSkeleton />;
  }

  return <>{children}</>;
};

export default RedirectUser;
