/* eslint-disable camelcase */
import React from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { P } from 'koba/components/Typography';

import { useCommunityContext } from 'components/CommunityContext';
import { isSiteAdmin } from 'utils/userHelpers';
import { CardContainer, CardList } from '@thinkific/toga-react';
import CircularSpinner from 'koba/components/CircularSpinner';
import Button from 'koba/components/Button';
import { UserEnrollmentFieldsFragment } from '__generated__/graphql/legacy/graphql';
import { relativeFormattedDateTime } from 'helpers/dateHelpers';

import styles from './ProfileCompletedCourses.module.scss';

const cx = classNames.bind(styles);

interface ProfileCompletedCourses {
  enrollments?: UserEnrollmentFieldsFragment | null;
  isLoadingMore: boolean;
  onLoadMoreCourses: () => void;
}

const ProfileCompletedCourses: React.FC<ProfileCompletedCourses> = ({
  enrollments,
  isLoadingMore,
  onLoadMoreCourses,
}) => {
  const { t } = useTranslation();
  const { currentUser } = useCommunityContext();

  const handleNavigation = (e, resumeCourseUrl, landingPageUrl) => {
    if (e.type === 'click' || e.key === 'Enter' || e.key === ' ') {
      window.location.assign(
        isSiteAdmin(currentUser.roles) ? landingPageUrl : resumeCourseUrl
      );
    }
  };

  if (!enrollments?.edges?.length) return null;

  return (
    <section className={cx('completed-courses__section')}>
      <P
        className={cx('completed-courses__section-title')}
        data-qa="completed-courses__section-title"
      >
        {t('COMPLETED COURSES')}
      </P>
      <CardContainer variation="list">
        {enrollments?.edges?.map((enrollment) => {
          const time = relativeFormattedDateTime(
            enrollment?.node?.completedAt,
            currentUser?.locale,
            t
          );

          return (
            <div
              className={cx('completed-courses__section-card')}
              data-qa="profile-course__card"
              key={enrollment?.node?.id}
              role="link"
              tabIndex={0}
              onClick={(event) =>
                handleNavigation(
                  event,
                  enrollment?.node?.course?.resumeCourseUrl,
                  enrollment?.node?.course?.landingPageUrl
                )
              }
              onKeyPress={(event) =>
                handleNavigation(
                  event,
                  enrollment?.node?.course?.resumeCourseUrl,
                  enrollment?.node?.course?.landingPageUrl
                )
              }
            >
              <CardList
                caption={t('Completed {{dateTime}}', {
                  dateTime: time,
                })}
                image={enrollment?.node?.course?.cardImageUrl || ''}
                name={enrollment?.node?.course?.name}
              />
            </div>
          );
        })}
      </CardContainer>
      <div className={cx('completed-courses__section-button')}>
        {isLoadingMore ? (
          <CircularSpinner variation="medium" />
        ) : (
          <>
            {enrollments?.pageInfo?.hasNextPage && (
              // @ts-ignore
              <Button
                appearance="ghost"
                className={cx('load-more')}
                data-qa="completed-course__button"
                onClick={onLoadMoreCourses}
              >
                {t('See More')}
              </Button>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default ProfileCompletedCourses;
